import { Injectable, Injector, inject } from "@angular/core";
import { Router } from "@angular/router";
import { REQUEST } from '@tytapp/express.tokens';
import { consoleImage } from './console-image';
import { environment } from '@tytapp/environment';
import { isClientSide, isServerSide } from '@tytapp/environment-utils';

@Injectable()
export class LoggerService {
    private request = inject(REQUEST, { optional: true });
    private router = inject(Router, { optional: true });

    static readonly global = Injector
        .create({ providers: [ { provide: LoggerService, useClass: LoggerService  } ]})
        .get(LoggerService)
    ;

    public async clientLogImage(url: string, backgroundColor?: string, scale?: number) {
        await consoleImage(url, backgroundColor, scale);
    }

    public clientLogWithStyles(message: string, style: string) {
        if (isServerSide())
            return;

        console.log(message, style);
    }

    public log(message: string, severity: 'error' | 'warn' | 'log' | 'info' | 'debug'  = 'log') {
        let logPrefix: string = `${new Date().toISOString()}`;

        if (Zone.current.get('requestId'))
            logPrefix += ` ${Zone.current.get('requestId')}`;

        if (this.request)
            logPrefix += ` ${this.request.ip} ${this.request.method} ${this.request.url}`;
        else if (this.router)
            logPrefix += ` (client) VIEW ${this.router.routerState.snapshot.url}`;

        message = `${logPrefix} | ${message}`;
        switch (severity) {
            case 'error':
                console.error(message);
                break;
            case 'warn':
                console.warn(message);
                break;
            case 'info':
                console.info(message);
                break;
            case 'debug':
                console.debug(message);
                break;
            case 'log':
            default:
                console.log(message);
        }
    }

    public error(message: string | Error) {
        if (message instanceof Error) {
            message = message.stack ?? String(message);
        }

        this.log(`[ERROR] ${message}`, 'error');
    }

    public warning(message: string) {
        this.log(`[WARNING] ${message}`, 'warn');
    }

    public info(message: string) {
        this.log(`[INFO] ${message}`, 'info');
    }

    public debug(message: string) {
        if (isServerSide() && !environment.serverLogging.verbose)
            return;
        if (isClientSide() && !environment.logging.verbose)
            return;

        this.log(`[DEBUG] ${message}`, 'debug');
    }

    public inspect(value: any) {
        console.dir(value);
    }
}