import { Injectable, NgZone, inject } from '@angular/core';
import { isClientSide } from '@tytapp/environment-utils';
import { iframeResizer } from 'iframe-resizer';

@Injectable()
export class IFrameResizer {
    private ngZone = inject(NgZone);

    install(element: HTMLElement | string) {
        if (!isClientSide())
            return;

        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => iframeResizer({ log: false }, element), 500);
        });
    }
}