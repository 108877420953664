/**
 * CAUTION: This file is automatically generated!
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ApiConfiguration } from './configuration';
import { Observable, throwError } from 'rxjs';

interface HttpRequestOptions {
    body?: any;
    headers?: { [header: string]: string | string[]; };
}

interface ApiRequestContext {
    traceStart: number;
}

export type RequestPerformer =
    (
        method: string,
        path: string,
        query: Record<string, string>,
        headers: Record<string, string|string[]>,
        body: any
    ) => Observable<any>
;

export class FailedToConnectError extends Error {
    constructor() {
        super('Failed to connect to backed. There may be a network issue.')
    }
}

@Injectable()
export class Requester {
    constructor(
        protected http: HttpClient,
        protected configuration: ApiConfiguration
    ) {

    }

    /**
     * Perform an API request. Called by generated APIs.
     */
    request<T>(
        method: string,
        path: string,
        query: Record<string, string>,
        headers: Record<string, string|string[]>,
        body?: any
    ): Observable<T> {
        return <Observable<T>> this.requestPerformer(method, path, query, headers, body);
    }

    /**
     * Defines how requests are performed by the API layer. Can be overridden to modify this behavior.
     */
    requestPerformer: RequestPerformer = (method, path, query, headers, body) =>
        this.performRequest(method, path, query, headers, body);

    logInfo = (message: string) => console.log(message);
    logWarning = (message: string) => console.warn(message);
    logError = (message: string) => console.error(message);

    /**
     * Default request performer. Can be called from a custom request performer to act as a middleware for
     * the normal API request procedure.
     *
     * @param method
     * @param path
     * @param query
     * @param headers
     * @param body
     * @returns
     */
    public performRequest<T>(
        method: string,
        path: string,
        query: Record<string, string>,
        headers: Record<string, string|string[]>,
        body?: any
    ) {
        let traceStart = Date.now();
        query = { ...this.configuration.globalQueryParams, ...query };
        path = `${this.configuration.basePath}${path}`;

        if (Object.keys(query).length > 0) {
            path = `${path}?${Object.keys(query)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`).join('&')}`;
        }

        let authState = this.configuration.accessToken ? `(authenticated) ` : ``;

        headers ??= {};

        this.applyGlobalHeaders(headers);

        if (body !== undefined)
            headers['Content-Type'] = 'application/json';

        let request = this.http
            .request<T>(method, path, {
                observe: 'response',
                responseType: 'json',
                withCredentials: this.configuration.withCredentials,
                headers,
                body: body == null ? null : JSON.stringify(body),
            })
            .pipe(
                catchError(e => {
                    let time = Date.now() - traceStart;

                    if (e instanceof HttpErrorResponse) {
                        this.logError(`[API] ${method} ${authState}${path} (${time} ms): Error: ${e.status} ${e.statusText}`);
                        e = e.error ? { ...e.error, $http: e } : e;
                    }

                    // On SSR when no TCP connection can be established, we get a raw ProgressEvent3 from the underlying
                    // xhr3 library, which is a dependency of Angular's HTTP client. In this case we clean up the error
                    // to avoid spewing a large JSON object to the console/logs
                    if (e.constructor.name === 'ProgressEvent') {
                        if (e.target?.status === 0) {
                            return throwError(new FailedToConnectError());
                        }

                        this.logError(`Unknown network error occurred, status=${e.target?.status ?? '<unknown>'}`);
                        return throwError(new Error(`An unknown network error occurred.`));
                    }

                    return throwError(e);
                }),
                map(response => {
                    let time = Date.now() - traceStart;
                    this.logInfo(`[API] ${method} ${authState}${path} (${time} ms): Finished: ${response.status} ${response.statusText}`);

                    return response;
                })
            )
        ;

        return this.prepareResult(request);
    }

    private prepareResult<T>(result : Observable<HttpResponse<T>>) {
        return result.pipe(
            catchError(e => { // e has keys like _body,status,ok,statusText,headers,type,url
                if (e.status === 0 || e.status === 500 || e.status === 504) {
                    return throwError({
                        message: 'A network error has occurred.',
                        requestDetails: {
                            status: e.status,
                            statusText: e.statusText,
                            url: e.url,
                            headers: e.headers
                        },
                        error: true,
                        errorType: 'network-error',
                        json: function() { return this; }
                    });
                }
                return throwError(e);
            }),
            map(response => {
                if (response.status === 204)
                    return undefined;

                let result : any = response.body;

                if (result && typeof result === 'object') {
                    if (response.headers.get('X-TYT-Authentication'))
                        result['x-tyt-authentication'] = response.headers.get('X-TYT-Authentication')

                    if (result instanceof Array) {
                        if (response.headers.get('X-Total'))
                            result['total'] = Number(response.headers.get('X-Total'));
                        else if (response.headers.get('X-TYT-Total'))
                            result['total'] = Number(response.headers.get('X-TYT-Total'));
                    }
                }

                return result;
            })
        );
    }

    private applyGlobalHeaders(headers : Record<string,string|string[]>) {
        if (this.configuration.accessToken) {
            let accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers['Authorization'] = 'Bearer ' + accessToken;
        }

        if (this.configuration.globalHeaders) {
            Object.keys(this.configuration.globalHeaders).forEach(key => {
                headers[key] = this.configuration.globalHeaders[key];
            })
        }
    }

}