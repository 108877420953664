
export function zeroPad(number: number | string, count: number = 2) {
    let str: string;

    if (typeof number === 'number')
        str = String(number);
    else
        str = number;

    while (str.length < count)
        str = '0' + str;

    return str;
}