import { Component } from '@angular/core';
import { BaseComponent } from './base-component';

/**
 * @deprecated TYT.com now uses API request reuse, so this is no longer necessary
 */
export function Transfer() {
    return (target: any, propertyKey: string) => {
        if (Reflect.defineMetadata)
            Reflect.defineMetadata('state-transfer:enabled', true, target, propertyKey);
    };
}

export interface StandardMeta {
    description?: string;
    keywords?: string;
    author?: string;
}

/**
 * Represents a component which is a page (or dialog). Such components are singletons, and are
 * typically the center component of a page.
 */
@Component({
    template: ''
})
export class PageComponent extends BaseComponent {
    public async handleNotFound<T>(promise: Promise<T>): Promise<T> {
        let result: T;
        try {
            return await promise;
        } catch (e) {
            if (e.error == 'not-found') {
                this.router.navigate([`/404/${this.router.routerState.snapshot.url}`]);
                return null;
            }

            throw e;
        }
    }

    /**
     * Whether a load error has been triggered or not. Used by <load-guard> to display an error when page data cannot be retrieved from the
     * API service.
     */
    public loadError: boolean = false;

    public set pageTitle(value: string) {
        this.shell.title = value;
    }

    public setMeta(meta: StandardMeta) {
        if (meta.description) this.meta.updateTag({ content: meta.description }, 'name=description');
        if (meta.keywords) this.meta.updateTag({ content: meta.keywords }, 'name=keywords');
        if (meta.author) this.meta.updateTag({ content: meta.author }, 'name=author');
    }

    private deflate(obj: any) {
        if (obj instanceof Array)
            return obj.map(x => this.deflate(x));

        if (obj !== null && obj !== undefined && typeof obj === 'object') {
            return {
                constructor: obj.constructor.name,
                data: JSON.stringify(obj)
            };
        }

        return obj;
    }

    protected addHeadLink(rel: string, type: string, href: string, title: string = null) {
        let link = this.head.addLink(rel, type, href, title);
        let self = this;

        this.subscribe(<any>{
            subscribe(...args) {
                return {
                    unsubscribe() {
                        self.head.removeLink(link);
                    }
                };
            }
        }, () => { });
    }

    /**
     * @deprecated No longer needed with the new API request state transfer. Eliminate this from all modules.
     */
    get hasServerState() {
        return false;
    }

    /**
     * Override this to rename the page component based on an input
     */
    get pageComponentId() {
        return undefined;
    }

    fireInit() {
        super.fireInit();
    }
}