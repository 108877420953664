
export function buildQuery(params: Record<string, any>) {
    return Object
        .keys(params)
        .filter(x => params[x] !== undefined)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&')
    ;
}

export function parseQuery(url: string): Record<string, string> {
    if (!url.includes('?'))
        url = `${url}?`;

    let queryString = url.slice(url.indexOf('?') + 1);
    return queryString
        .split('&')
        .map(str => [
            decodeURIComponent(str.includes('=') ? str.slice(0, str.indexOf('=')) : str),
            decodeURIComponent(str.includes('=') ? str.slice(str.indexOf('=') + 1) : '')
        ])
        .reduce((o, [k, v]) => (o[k] = v, o), {})
    ;
}

export function isExternalLink(url: string) {
    return url.startsWith('https://');
}