import { inject, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Injectable } from '@banta/common';
import { DevToolsAction, DevToolsMenu, DevToolsMenuItem } from '@tytapp/app/dev';
import { environment } from '@tytapp/environment';
import { isServerSide } from '@tytapp/environment-utils';
import { LoggerService } from './logger.service';

interface Environment {
    id: string;
    label: string;
    icon: string;
}

const ENVIRONMENTS: Environment[] = [
    { id: '', label: 'Default', icon: 'code' },
    { id: 'staging', label: 'Staging', icon: 'approval' },
    { id: 'production', label: 'Production', icon: 'precision_manufacturing' },
];

function changeEnvironment(mode: string) {
    if (isServerSide())
        return;

    localStorage.setItem('tyt-api-override', mode);
    window.location.reload();
}


@Injectable()
export class DevToolsService {
    private injector = inject(Injector);
    private logger = inject(LoggerService);

    triggerActionById(id: string) {
        let action = this.getActionById(id);
        if (!action) {
            this.logger.error(`No dev action with ID '${id}'`);
        } else {
            this.logger.warning(`Running dev action with ID '${id}'`);
            action.handler(action, this.injector);
        }
    }

    getItemById(id: string) {
        let selectedItem: DevToolsMenuItem;
        this.visitMenu(menuItem => {
            if (menuItem.id === id) {
                selectedItem = menuItem;
                return false;
            } else {
                return true;
            }
        });

        return selectedItem;
    }

    appendInto(id: string, items: DevToolsMenuItem[]) {
        let found = !this.visitMenu((menuItem) => {
            if (menuItem.type === 'menu' && menuItem.id === id) {
                menuItem.items.push(...items);
                return false;
            } else {
                return true;
            }
        });

        if (!found && environment.showDevTools)
            throw new Error(`DevTools: No such menu item '${id}' exists!`);
    }

    prependInto(id: string, items: DevToolsMenuItem[]) {
        let found = !this.visitMenu((menuItem) => {
            if (menuItem.type === 'menu' && menuItem.id === id) {
                menuItem.items.unshift(...items);
                return false;
            } else {
                return true;
            }
        });

        if (!found && environment.showDevTools)
            throw new Error(`DevTools: No such menu item '${id}' exists!`);
    }

    insertAfter(id: string, items: DevToolsMenuItem[]) {
        let found = !this.visitMenu((menuItem, menu) => {
            if (menuItem.type === 'action' && menuItem.id === id) {
                let index = menu.items.indexOf(menuItem);
                menu.items.splice(index + 1, 0, ...items);
                return false;
            } else {
                return true;
            }
        });

        if (!found && environment.showDevTools)
            throw new Error(`DevTools: No such menu item '${id}' exists!`);
    }

    insertBefore(id: string, items: DevToolsMenuItem[]) {
        let found = !this.visitMenu((menuItem, menu) => {
            if (menuItem.type === 'action' && menuItem.id === id) {
                let index = menu.items.indexOf(menuItem);
                menu.items.splice(index, 0, ...items);
                return false;
            } else {
                return true;
            }
        });

        if (!found && environment.showDevTools)
            throw new Error(`DevTools: No such menu item '${id}' exists!`);
    }

    getActionById(id: string) {
        let selectedItem: DevToolsAction;
        this.visitMenu(menuItem => {
            if (menuItem.type === 'action' && menuItem.id === id) {
                selectedItem = menuItem;
                return false;
            } else {
                return true;
            }
        });

        return selectedItem;
    }

    getMenuById(id: string) {
        let selectedItem: DevToolsMenu;
        this.visitMenu(menuItem => {
            if (menuItem.type === 'menu' && menuItem.id === id) {
                selectedItem = menuItem;
                return false;
            } else {
                return true;
            }
        });

        return selectedItem;
    }

    visitMenu(visitor: (item: DevToolsMenuItem, parent: DevToolsMenu) => boolean, item: DevToolsMenuItem = this.rootMenu, parent: DevToolsMenu = null) {
        if (!visitor(item, parent))
            return false;

        if (item.type === 'menu') {
            for (let subItem of item.items) {
                if (!this.visitMenu(visitor, subItem, item))
                    return false;
            }
        }

        return true;
    }

    rootMenu: DevToolsMenu = {
        type: 'menu',
        icon: 'code',
        id: 'root',
        label: 'Dev Tools',
        items: [
            {
                type: 'action',
                label: 'Dev Tools',
                icon: 'close',
                handler: () => environment.showDevTools = false
            },
            {
                type: 'menu',
                label: 'Navigation',
                icon: 'link',
                items: [
                    {
                        type: 'action',
                        label: 'Reload',
                        icon: 'refresh',
                        handler: () => window.location.reload()
                    },
                    {
                        type: 'action',
                        label: 'Back',
                        icon: 'arrow_back',
                        handler: () => window.history.back()
                    },
                    {
                        type: 'action',
                        get label() {
                            return `Show URL`
                        },
                        icon: 'link',
                        handler: () => alert(window.location.href)
                    },
                    {
                        type: 'action',
                        get label() {
                            return `Copy URL`
                        },
                        icon: 'content_copy',
                        handler: () => navigator.clipboard.writeText(window.location.href)
                    },
                    {
                        type: 'action',
                        label: 'Open External Page',
                        icon: 'link',
                        handler: () => window.location.assign('https://google.com')
                    },
                    {
                        type: 'action',
                        label: 'Open External in New Tab',
                        icon: 'link',
                        handler: () => window.open('https://google.com', '_blank', 'noopener')
                    },
                    {
                        type: 'action',
                        label: 'Test 404-based Redirection',
                        icon: 'public',
                        handler: (item, injector) => injector.get(Router).navigateByUrl('/engn-test-redirect')
                    },
                    {
                        type: 'menu',
                        id: 'testAreas',
                        label: 'Test Areas',
                        icon: 'science',
                        items: [
                            {
                                type: 'action',
                                label: 'Comments',
                                handler: (item, injector) => injector.get(Router)
                                    .navigateByUrl('/engineering/comments')
                            },
                            {
                                type: 'action',
                                label: 'Style Guide',
                                handler: (item, injector) => injector.get(Router)
                                    .navigateByUrl('/engineering/style-guide')
                            },
                            {
                                type: 'action',
                                label: 'Panic',
                                handler: (item, injector) => injector.get(Router)
                                    .navigateByUrl('/engineering/panic')
                            },
                            {
                                type: 'action',
                                label: 'Login Status',
                                handler: (item, injector) => injector.get(Router)
                                    .navigateByUrl('/engineering/login-status')
                            },
                            {
                                type: 'action',
                                label: 'Link Testing',
                                handler: (item, injector) => injector.get(Router)
                                    .navigateByUrl('/engineering/link-testing')
                            },
                            {
                                type: 'action',
                                label: 'Link Hash Testing',
                                handler: (item, injector) => injector.get(Router)
                                    .navigateByUrl('/engineering/link-hash-testing')
                            },
                            {
                                type: 'action',
                                label: 'Notifications',
                                handler: (item, injector) => injector.get(Router)
                                    .navigateByUrl('/engineering/notifications')
                            },
                        ]
                    }
                ]
            },
            {
                type: 'menu',
                id: 'global',
                label: 'Global',
                icon: 'settings',
                items: [
                    {
                        type: 'action',
                        label: 'Show Dev Notes',
                        icon: 'bug_report',
                        get hidden() {
                            return environment.showDevNotes;
                        },
                        handler: (injector, item) => {
                            environment.showDevNotes = true;
                        }
                    },
                    {
                        type: 'action',
                        label: 'Hide Dev Notes',
                        icon: 'bug_report',
                        get hidden() {
                            return !environment.showDevNotes;
                        },
                        handler: (injector, item) => {
                            environment.showDevNotes = false;
                        }
                    },
                    {
                        type: 'menu',
                        id: 'environments',
                        label: 'Environments',
                        icon: 'burst_mode',
                        items: ENVIRONMENTS.map(env => ({
                            type: 'action',
                            id: `env:${env.id}`,
                            icon: env.icon,
                            label: env.label,
                            handler: () => changeEnvironment(env.id)
                        }))
                    },
                    {
                        type: 'menu',
                        id: 'connectivity',
                        label: 'Connectivity',
                        icon: 'network_check',
                        items: [
                            {
                                type: 'action',
                                label: 'Auto (No emulation)',
                                icon: 'radar',
                                handler: (injector, item) => {
                                    delete localStorage['tyt:online'];
                                    location.reload();
                                }
                            },
                            {
                                type: 'action',
                                label: 'Emulate Offline',
                                icon: 'cloud_off',
                                handler: (injector, item) => {
                                    localStorage['tyt:online'] = '0';
                                    location.reload();
                                }
                            },
                            {
                                type: 'action',
                                label: 'Emulate Online',
                                icon: 'cloud',
                                handler: (injector, item) => {
                                    localStorage['tyt:online'] = '1';
                                    location.reload();
                                }
                            },
                        ]
                    },
                    {
                        type: 'action',
                        label: 'Force panic',
                        icon: 'priority_high',
                        handler() {
                            throw new Error("This is a forced panic for development purposes.")
                        }
                    }
                ]
            }
        ]
    };
}
